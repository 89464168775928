.navbar{
    background-color: #8843F2;
}

.navlink{
  color: white !important;
}
.navlink:hover{
  color: white !important;
}
/* ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  li {
    float: left;
  }
  
  li a {
    display: block;
    padding: 1rem;
    color: white;
    text-decoration: none;
  } */